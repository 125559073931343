import React from 'react';

import MetaTags from '@/components/meta-tags';
import SuperBadge from '@/features/super-badge';

const SuperBadgePage = () => {
  return <SuperBadge />;
};

export default SuperBadgePage;

export function Head() {
  return (
    <>
      <title>Super Champs HQ - Super Badge</title>
      <script
        src="https://cdn.withpersona.com/dist/persona-v4.11.0.js"
        id="persona"
      ></script>
      <MetaTags />
    </>
  );
}
