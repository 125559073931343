import { updateKyc } from '@/api/quests';

const UpdateKyc = async (userId: string, personaVerificationId: string) => {
  try {
    const data = await updateKyc(userId, personaVerificationId);
    return data;
  } catch (e) {
    console.error('Error in UpdateKyc \n', e);
  }
};

export default UpdateKyc;
