import { Dispatch, SetStateAction } from 'react';

import { INotification } from '@/components/common/notification-modal';

import { TTask } from '../types';

import DiscordConnect from './discord-connect';
import Invite from './invite';
import OpenRandomLink from './open-random-link';
import OpenRandomLinkWithPopulatingReferralCode from './open-random-link-referral';
import SocialActivity from './social-activity';
import TwitterConnect from './twitter-connect';
import TwitterMetrics from './twitter-metrics';
import UpdateKyc from './update-kyc';
import ValidateAndUpdateReferralCode from './update-referral-code';
import VerifyDiscordAction from './verify-discord-action';
import VerifyDiscordConnect from './verify-discord-connect';
import VerifySoulBoundNft from './verify-soul-bound-nft';
import VerifyTwitterAction from './verify-twitter-action';
import VerifyTwitterConnect from './verify-twitter-connect';
import VerifyUserAction from './verify-user-action';
import VerifyYoutubeAction from './verify-youtube-action';
import VerifyYoutubeConnect from './verify-youtube-connect';
import YoutubeConnect from './youtube-connect';
import YouTubeMetrics from './youtube-metrics';

export type TTaskList = {
  invite: (userId: string) => Promise<void | ''>;
  twitterConnect: (userId: string, quest: TTask) => Promise<void>;
  discordConnect: (quest: TTask) => Promise<void>;
  youtubeConnect: (quest: TTask) => Promise<void>;
  verifyTwitterAction: (
    userId: string,
    twitterUrl: string,
    payload: any,
  ) => Promise<any>;
  verifyUserAction: (userId: string, payload: any) => Promise<any>;
  verifyDiscordAction: (userId: string, payload: any) => Promise<boolean>;
  verifyYoutubeAction: (userId: string, payload: any) => Promise<boolean>;
  verifySoulBoundNft: (userId: string) => Promise<any>;
  updateKyc: (userId: string, personaVerificationId: string) => Promise<void>;
  verifyDiscordConnect: (
    userId: string,
    setNotification: Dispatch<SetStateAction<INotification>>,
  ) => Promise<boolean | undefined>;
  verifyYoutubeConnect: (
    userId: string,
    setNotification: Dispatch<SetStateAction<INotification>>,
  ) => Promise<boolean | undefined>;
  verifyTwitterConnect: (
    userId: string,
    setNotification: Dispatch<SetStateAction<INotification>>,
  ) => Promise<boolean | undefined>;
  validateAndUpdateReferralCode: (
    userId: string,
    payload: {
      nftId: string;
      referralCode: string;
    },
  ) => Promise<void>;
  socialActivity: (userId: string, quest: TTask) => any;
  twitterMetrics: (userId: string, quest: TTask, setTask: any) => Promise<void>;
  youtubeMetrics: (userId: string, quest: TTask, setTask: any) => Promise<void>;
  openRandomLink: (quest: TTask) => any;
  openRandomLinkWithPopulatingReferralCode: (
    quest: TTask,
    referralCode: string,
  ) => any;
};

const Tasks: TTaskList = {
  invite: Invite,
  twitterConnect: TwitterConnect,
  discordConnect: DiscordConnect,
  youtubeConnect: YoutubeConnect,
  verifyTwitterAction: VerifyTwitterAction,
  verifyUserAction: VerifyUserAction,
  verifyDiscordAction: VerifyDiscordAction,
  verifyYoutubeAction: VerifyYoutubeAction,
  verifySoulBoundNft: VerifySoulBoundNft,
  updateKyc: UpdateKyc,
  verifyDiscordConnect: VerifyDiscordConnect,
  verifyYoutubeConnect: VerifyYoutubeConnect,
  verifyTwitterConnect: VerifyTwitterConnect,
  validateAndUpdateReferralCode: ValidateAndUpdateReferralCode,
  socialActivity: SocialActivity,
  twitterMetrics: TwitterMetrics,
  youtubeMetrics: YouTubeMetrics,
  openRandomLink: OpenRandomLink,
  openRandomLinkWithPopulatingReferralCode:
    OpenRandomLinkWithPopulatingReferralCode,
};

export default Tasks;
