import { navigate } from 'gatsby';
import React, { Dispatch, SetStateAction } from 'react';

import { verifyYoutubeConnect } from '@/api/quests';
import CONSTANTS from '@/common/constants';
import { isBrowser, localStorage } from '@/common/utils';
import { INotification } from '@/components/common/notification-modal';

import { TTask } from '../types';

const VerifyYoutubeConnect = async (
  userId: string,
  setNotification: Dispatch<SetStateAction<INotification>>,
): Promise<boolean | undefined> => {
  try {
    if (!localStorage.getItem('YOUTUBE_DETAILS')) {
      const params = new URLSearchParams(
        isBrowser() ? window.location.search : '',
      );
      const code = params.get('code');
      if (!code) {
        return false;
      }
      const data = await verifyYoutubeConnect({
        code,
        codeVerifier: localStorage.getItem('YOUTUBE_CODE_VERIFIER') || '',
        userId,
        appId: CONSTANTS.JRX_APP_ID,
        platform: 'Web',
      });
      if (data) {
        const quest: TTask = JSON.parse(
          localStorage.getItem('QUEST_IN_PROGRESS') || '',
        );
        if (!quest) {
          return false;
        }
        localStorage.removeItem('QUEST_IN_PROGRESS');
        console.log('data', data);
        const payload = {
          youtubeUserId: data.youtubeUserId,
          youtubeAuthScope: data.authScope,
          actionType:
            quest.challengeKey.challengeTask === 'youtubeVerifyRole'
              ? 'YOUTUBE_VERIFY_ROLE'
              : '',
          platform: 'Web',
          refreshToken: data.refreshToken,
        };
        localStorage.setItem('YOUTUBE_DETAILS', JSON.stringify(payload));

        // opening task link in a new tab and redirecting the user to /quests page
        // if (isBrowser() && quest) {
        //   window.open(quest.challengeRedirection, '_blank');
        // }

        navigate('/quests');
        return true;
      }
    } else {
      navigate('/quests');
      return true;
    }
  } catch (e: any) {
    navigate('/quests');
    if (e?.data?.errorCode === 'social_id_already_in_use') {
      setNotification({
        show: true,
        message: (
          <h4 className="tac mb-4">
            Youtube is already linked to an existing account. Please connect
            with a different account.
          </h4>
        ),
        ctaText: 'OKAY',
      });
    } else {
      setNotification({
        show: true,
        message: (
          <h4 className="tac mb-4">
            Youtube authorisation failed. Please try again.
          </h4>
        ),
        ctaText: 'OKAY',
      });
    }
    return false;
  }
};

export default VerifyYoutubeConnect;
