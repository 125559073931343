import { connectDiscord } from '@/api/quests';
import LStorage from '@/common/storage';
import utils, { isBrowser, localStorage } from '@/common/utils';

import { TTask } from '../types';

const DiscordConnect = async (quest: TTask) => {
  const loader = utils.el('quests-loader');
  loader!.classList.add('show');
  try {
    if (localStorage.getItem('DISCORD_DETAILS_V2')) {
      if (isBrowser()) {
        window.open(quest.challengeRedirection, '_blank');
        const tasks = LStorage.get('SOCIAL_ACTIVITY_TASKS_V2') || [];
        LStorage.set('SOCIAL_ACTIVITY_TASKS_V2', [...tasks, quest.id]);
      }
      return;
    } else {
      // no existing discord details, so connect discord
      const data = await connectDiscord();
      // open the URL in browser
      localStorage.setItem('QUEST_IN_PROGRESS', JSON.stringify(quest));
      if (isBrowser()) {
        window.open(data.authorizationUrl, '_blank');
      }
    }
  } catch (e) {
    console.error('Error in DiscordConnect \n', e);
  } finally {
    loader!.classList.remove('show');
  }
};

export default DiscordConnect;
