import { navigate } from 'gatsby';
import React, { Dispatch, SetStateAction } from 'react';

import { verifyDiscordConnect } from '@/api/quests';
import CONSTANTS from '@/common/constants';
import { isBrowser, localStorage } from '@/common/utils';
import { INotification } from '@/components/common/notification-modal';

import { TTask } from '../types';

const VerifyDiscordConnect = async (
  userId: string,
  setNotification: Dispatch<SetStateAction<INotification>>,
): Promise<boolean | undefined> => {
  try {
    // if localStorage.DISCORD_DETAILS is not there
    if (!localStorage.getItem('DISCORD_DETAILS_V2')) {
      console.log('no discord details!');
      const params = new URLSearchParams(
        isBrowser() ? window.location.search : '',
      );
      const code = params.get('code');
      if (!code) {
        return false;
      }
      const data = await verifyDiscordConnect({
        code,
        codeVerifier: localStorage.getItem('DISCORD_CODE_VERIFIER') || '',
        userId,
        appId: CONSTANTS.JRX_APP_ID,
        platform: 'Web',
      });
      if (data) {
        const quest: TTask = JSON.parse(
          localStorage.getItem('QUEST_IN_PROGRESS') || '',
        );
        if (!quest) {
          return false;
        }
        localStorage.removeItem('QUEST_IN_PROGRESS');

        const payload = {
          discordUserId: data.discordUserId,
          discordRoleId: quest.challengeSecondaryKey.roleId,
          discordGuildId: quest.challengeSecondaryKey.guildId,
          actionType:
            quest.challengeKey.challengeTask === 'discordVerifyRole'
              ? 'VERIFY_ROLE'
              : '',
          platform: 'Web',
          refreshToken: data.refreshToken,
        };
        localStorage.setItem('DISCORD_DETAILS_V2', JSON.stringify(payload));

        // opening task link in a new tab and redirecting the user to /quests page
        if (isBrowser() && quest) {
          window.open(quest.challengeRedirection, '_blank');
        }

        navigate('/quests');
        // toast.success('Discord connection was successful!');
        return true;
      }
    } else {
      navigate('/quests');
      // toast.success('Discord already connected!');
      return true;
    }
  } catch (e: any) {
    navigate('/quests');
    if (e?.data?.errorCode === 'social_id_already_in_use') {
      setNotification({
        show: true,
        message: (
          <h4 className="tac mb-4">
            Discord is already linked to an existing account. Please connect
            with a different account.
          </h4>
        ),
        ctaText: 'OKAY',
      });
      // toast.error('Failed to connect, Discord account already in use!');
    } else {
      // console.error('Error in VerifyDiscordConnect \n', e);
      // toast.error('Discord connection failed!');
      setNotification({
        show: true,
        message: (
          <h4 className="tac mb-4">
            Discord authorisation failed. Please try again.
          </h4>
        ),
        ctaText: 'OKAY',
      });
    }
    return false;
  }
};

export default VerifyDiscordConnect;
