import React, { FC, useEffect } from 'react';

import { commonBiEvents } from '@/bi/events/common-bi-events';
import { getCurrentTabForBi } from '@/common/utils';
import { useWeb3Context } from '@/context/web3-context';

const Fee: FC = () => {
  const { badgeFee } = useWeb3Context();
  const onLoad = async () => {
    commonBiEvents.view({
      event_name: 'kyc_client',
      str_field1: 'super_badge_screen',
      str_field3: getCurrentTabForBi(),
      str_field10: badgeFee,
    });
  };

  useEffect(() => {
    if (badgeFee) {
      onLoad();
    }
    // eslint-disable-next-line
  }, [badgeFee]);

  return <span>{badgeFee || '. . .'}</span>;
};
export default Fee;
