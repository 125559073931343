import utils from '@/common/utils';
import Tasks from '@/features/quests/tasks';

const personaClient = (
  userId: string,
  {
    onReady,
    onComplete,
  }: {
    onReady?: () => void;
    onComplete?: (inquiryId: string, status: string) => void;
  } = {},
) => {
  const p = (window as any).Persona;
  const loader = utils.el('quests-loader');
  loader?.classList.add('show');
  const client = new p.Client({
    templateId: 'itmpl_3sP8WpqAqaKDRU2vCrJk111W1vMP',
    environmentId:
      utils.isProd() || utils.isStage()
        ? 'env_Wadrh9vp1Y55mcGfTfgQ5Kskzngj'
        : 'env_Ebptu3uNtPkoW19Rvcwnbbvjwbar',
    referenceId: userId,
    onReady: () => {
      client.open();
      loader?.classList.remove('show');
      onReady && onReady();
    },
    onComplete: async ({ inquiryId, status, fields }: any) => {
      console.log('🚀 ~ status:', status);
      await Tasks.updateKyc(userId, inquiryId);
      onComplete && onComplete(inquiryId, status);
    },
  });
};

export default personaClient;
