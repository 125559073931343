import { connectTwitter } from '@/api/quests';
import utils, { isBrowser, localStorage } from '@/common/utils';

import { TTask } from '../types';

const TwitterMetrics = async (userId: string, quest: TTask, setTask: any) => {
  const loader = utils.el('quests-loader');
  loader!.classList.add('show');
  try {
    const twitterDetails = JSON.parse(
      localStorage.getItem('TWITTER_DETAILS') || '{}',
    );
    if (twitterDetails?.userAccessTokenData?.access_token) {
      setTask(quest);
      return;
    } else {
      // no existing twitter details, so connect twitter
      const data = await connectTwitter(userId);
      // open the URL in browser
      localStorage.setItem('QUEST_IN_PROGRESS', JSON.stringify(quest));
      if (isBrowser()) {
        window.open(data.authUrl, '_blank');
      }
    }
  } catch (e) {
    console.error('Error in TwitterMetrics \n', e);
  } finally {
    loader!.classList.remove('show');
  }
};

export default TwitterMetrics;
