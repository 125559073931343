import { navigate } from 'gatsby';
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import { useLinkedAddress } from '@/api/api-hooks';
import { commonBiEvents } from '@/bi/events/common-bi-events';
import { getCurrentTabForBi, isBrowser } from '@/common/utils';
import Modal from '@/components/modal-v2';
import { useAuth } from '@/context/auth-context';
import { useSuperChampsContext } from '@/context/superchamps-context';
import { useWeb3Context } from '@/context/web3-context';

import UnsupportedCountry from '../auth-v2/unsupported-country-view';
import { FetchError } from '../quests/errors';
import Tasks from '../quests/tasks';
import personaClient from '../wallet/components/kyc';

export interface IMintSuperBadge {
  isSbNftMinted: () => boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setLoadingText: Dispatch<SetStateAction<string>>;
}

const MintSuperBadge: FC<IMintSuperBadge> = ({
  isSbNftMinted,
  setIsLoading,
  setLoadingText,
}) => {
  const {
    user,
    fetchSocialData,
    socialData,
    setShowLogin,
    showLogin,
    setStep,
  } = useAuth();
  const { setNotification } = useSuperChampsContext();
  const { linkedAddress } = useLinkedAddress();
  const {
    mintSoulBoundNft,
    checkIfSoulBoundNftMinted,
    switchBlockchain,
    checkEthBalance,
    badgeFee,
  } = useWeb3Context();
  const [enableMintButton, setEnableMintButton] = useState<boolean>(false);
  const [isMinting, setIsMinting] = useState<boolean>(false);
  const [isMinted, setIsMinted] = useState<boolean>(false);
  const [showUnsupportedCountry, setShowUnsupportedCountry] =
    useState<boolean>(false);
  const [showSuperBadge, setShowSuperBadge] = useState<boolean>(false);
  const [showKycProgress, setShowKycProgress] = useState<boolean>(false);

  const toggleMintButton = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = e;
    setEnableMintButton(checked);
  };

  useEffect(() => {
    if (socialData && !isSbNftMinted()) {
      checkIfNftIsMinted();
    }
    setTimeout(() => {
      switchBlockchain();
    }, 2000);

    // eslint-disable-next-line
  }, [socialData]);

  useEffect(() => {
    if (showSuperBadge) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showSuperBadge]);

  useEffect(() => {
    setIsMinted(isSbNftMinted());
    // eslint-disable-next-line
  }, [user, socialData]);

  useEffect(() => {
    if (showLogin) {
      setIsMinted(false);
    }
    // eslint-disable-next-line
  }, [showLogin]);

  const isKycVerified = () => {
    if (socialData) {
      return socialData?.kycStatus === 'VERIFIED';
    }
    return false;
  };

  // const isUSCitizen = () => {
  //   if (socialData) {
  //     return socialData?.userKycData?.residenceCountryCode === 'US' || false;
  //   }
  //   return false;
  // };

  const checkCountryForMint = () => {
    startSoulBoundNftFlow();
    commonBiEvents.click({
      event_name: 'kyc_client',
      str_field1: 'mint',
      str_field2: 'super_badge_screen',
      str_field3: getCurrentTabForBi(),
      str_field10: badgeFee,
    });
    // if (isUSCitizen()) {
    //   setShowUnsupportedCountry(true);
    // } else {
    //   startSoulBoundNftFlow();
    // }
  };

  const startMinting = async () => {
    setShowKycProgress(false);
    setIsMinting(true);
    setLoadingText("Don't refresh the page, minting is in progress..!");

    try {
      const balance = await checkEthBalance();
      if (!(balance > 0)) {
        setNotification({
          show: true,
          message: (
            <h4 className="tac mb-4">
              Insufficient ETH Balance: Add funds to your Wallet and try minting
              again.
            </h4>
          ),
          ctaText: 'Okay',
        });
        setIsMinting(false);
        return;
      }
      const data = await mintSoulBoundNft();

      if (data) {
        const sbNftVerified = await Tasks.verifySoulBoundNft(
          user?.appUser?.user?.id || '',
        );
        if (sbNftVerified) {
          await fetchSocialData();
        }
        if (!isBrowser()) return;
        const sdData = localStorage.SOCIAL_DATA
          ? JSON.parse(localStorage.SOCIAL_DATA)
          : null;
        // opens super badge after successful mint
        setIsMinted(true);
        showSuccessBadge();

        commonBiEvents.stateChange({
          event_name: 'kyc_client',
          str_field1: 'success',
          str_field2: 'super_badge_mint',
          str_field5: sdData?.userKycData?.citizenshipCountryCode || '',
          str_field6: sdData?.userKycData?.residenceCountryCode || '',
          str_field10: badgeFee,
        });
      }
    } catch (e: any) {
      const message = FetchError(
        e.reason === 'rejected' ? 'rejected_mint' : e.reason,
        'Failed minting Super Badge.',
      );
      setNotification({
        show: true,
        message: <h4 className="tac mb-4">{message}</h4>,
        ctaText: 'MINT NOW',
        onCtaClick: () => {
          startMinting();
        },
      });
      console.error('Error in startMinting \n', e);
    }
    setShowKycProgress(false);
    setIsMinting(false);
  };

  const checkIfNftIsMinted = async () => {
    try {
      const data = await checkIfSoulBoundNftMinted(linkedAddress);
      if (data) {
        Tasks.verifySoulBoundNft(user?.appUser.user.id || '');
        // toast.success('Soul Bound NFT minted successfully!');
      }
    } catch (e) {
      console.error('Error in checkIfNftIsMinted \n', e);
    }
  };

  const startSoulBoundNftFlow = () => {
    if (!user?.appUser?.user?.id) {
      toast.info('Please login to mint the Soul Bound NFT!');
      return;
    }

    // KYC verified flow
    if (isKycVerified()) {
      startMinting();
    }

    // KYC not verified flow
    if (!isKycVerified() && user?.appUser?.user?.id) {
      setShowKycProgress(true);
      setIsLoading(true);
      personaClient(user?.appUser?.user?.id, {
        onReady: () => {
          setIsLoading(false);
        },
        onComplete: async (inquiryId, status) => {
          if (status === 'completed' || status === 'approved') {
            await fetchSocialData();
            startMinting();
          }
          setIsLoading(false);
        },
      });
    }
  };

  const showSuccessBadge = () => {
    setShowSuperBadge(true);
    setTimeout(() => {
      setShowSuperBadge(false);
    }, 30000);
  };

  return (
    <>
      {isMinted ? (
        <>
          <button
            className="yellow-button small"
            onClick={() => showSuccessBadge()}
          >
            VIEW SUPER BADGE
          </button>
          <button
            className="yellow-button small ml-3"
            onClick={() => navigate('/quests')}
          >
            GO BACK TO QUESTS
          </button>
        </>
      ) : (
        <>
          <div className="check-box-ui mt-3">
            <div className="verify-checkbox form-group">
              <input
                type="checkbox"
                className="form-check-input"
                id="nft-certify-withdraw-address"
                onChange={toggleMintButton}
                checked={enableMintButton}
              />
              <label
                className="label form-check-label h5 translucent"
                htmlFor="nft-certify-withdraw-address"
              >
                Please read our{' '}
                <a
                  className="yellow-link underline"
                  target="_blank"
                  rel="noreferrer"
                  href="https://superchamps.com/privacy"
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  className="yellow-link underline"
                  target="_blank"
                  rel="noreferrer"
                  href="https://superchamps.com/tos"
                >
                  Terms of Service
                </a>{' '}
              </label>
            </div>
          </div>

          {user && (
            <button
              className="yellow-button small"
              disabled={!enableMintButton || showKycProgress}
              onClick={checkCountryForMint}
            >
              {showKycProgress ? 'VERIFYING, PLEASE WAIT...' : 'MINT NOW'}
            </button>
          )}
          {!user && (
            <button
              className="yellow-button small"
              onClick={() => {
                // showLogin
                setStep('login');
                setShowLogin(true);
              }}
            >
              LOGIN TO MINT
            </button>
          )}
        </>
      )}

      <Modal
        isOpen={showUnsupportedCountry}
        className="medium"
        onClose={() => setShowUnsupportedCountry(false)}
      >
        <UnsupportedCountry
          ctaText="Continue to Mint"
          onContinue={() => {
            setShowUnsupportedCountry(false);
            startSoulBoundNftFlow();
          }}
        />
      </Modal>

      <Modal isOpen={isMinting} className="medium">
        <div className="flex aic jcc">
          <div className="p-4">
            <h3 className="tac">MINTING IN PROGRESS</h3>
            <p className="tac">
              Approve your transaction on Wallet, Please don't refresh the page
            </p>

            <div
              className="spinning-loader tac"
              style={{ width: '75px', height: '75px', margin: 'auto' }}
            ></div>
          </div>
        </div>
      </Modal>

      {showSuperBadge && (
        <div className="super-badge-overlay">
          <div className="close" onClick={() => setShowSuperBadge(false)}>
            &times;
          </div>
          <div className="super-badge-glow"></div>
          <div className="super-badge"></div>
        </div>
      )}
    </>
  );
};

export default MintSuperBadge;
