import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';

import SCLoader from '@/components/common/sc-loader';
import SuperChampsLayout from '@/components/layout/superchamps-layout';
import { useAuth } from '@/context/auth-context';
import { useSuperChampsContext } from '@/context/superchamps-context';

import { SeasonStateEnum } from '../quests/types';

import Fee from './fee';
import MintSuperBadge from './mint-super-badge';

const SuperBadge = () => {
  const { seasonState } = useSuperChampsContext();
  const { socialData, showLogin, fetchSocialData } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string>('');
  const [isMinted, setIsMinted] = useState<boolean>(false);

  const isSbNftMinted = () => {
    if (socialData) {
      return socialData?.userSocialNftData?.accessPassNftHolder || false;
    }
    return false;
  };

  const onLoad = async () => {
    /** TODO: IMPORTANT: to be disabled after season 0 */
    if (seasonState === SeasonStateEnum.PRE_SEASON) {
      navigate('/quests');
    }
    /** TODO: IMPORTANT: to be disabled after season 0 */
  };

  useEffect(() => {
    setIsMinted(isSbNftMinted());
    // eslint-disable-next-line
  }, [socialData]);

  useEffect(() => {
    if (showLogin) {
      setIsMinted(false);
    }
    // eslint-disable-next-line
  }, [showLogin]);

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showLogin) {
      fetchSocialData();
    }
    // eslint-disable-next-line
  }, [showLogin]);

  return (
    <SuperChampsLayout className="super-badge-page">
      <div className="super-badge-preload"></div>
      <div className="container">
        <div className="super-badge-hero-container">
          <div className="hero-container">
            <div className={`hero-badge ${isMinted ? 'show' : ''}`}></div>
            <div className={`hero ${isMinted ? 'show' : ''}`}></div>
            {!isMinted && <div className="lock"></div>}
          </div>
          <div className="content">
            <h1>SUPER BADGE</h1>
            <h3>
              YOUR PASSPORT TO EXCLUSIVE
              <br />
              SUPER CHAMPS REWARDS
            </h3>
            <div className="boxed-details">
              <div className="box">
                <div className="box-head">
                  ISSUING FEE:{' '}
                  <span className="yellow">
                    <Fee /> ETH
                  </span>
                </div>
                <div className="h5 translucent">
                  Covers authentication partner costs of
                  <br />
                  approximately $5 based on current ETH value.
                </div>
              </div>
              <div className="box">
                <div className="box-head">
                  GAS FEE: <span className="yellow">Dynamic</span>
                </div>
                <div className="h5 translucent">
                  Per current BASE network
                  <br />
                  rates.
                </div>
              </div>
            </div>
            <div className="mt-4 mb-3">
              <MintSuperBadge
                isSbNftMinted={isSbNftMinted}
                setIsLoading={setIsLoading}
                setLoadingText={setLoadingText}
              />
            </div>
          </div>
        </div>

        <div className="super-badge-container">
          <div className="super-badge-steps-container">
            <h2 className="h2">MINT YOUR SUPER BADGE</h2>
            <p>Get your exclusive access in two easy steps…</p>

            <div className="black-steps-container">
              <div className="step">
                <div className="content">
                  <h3>
                    VERIFY YOUR
                    <br />
                    IDENTITY
                  </h3>
                  <p>
                    Provide official identification
                    <br />
                    documentation such as your passport
                    <br />
                    or driver's license. Have documents
                    <br />
                    ready before you begin.
                  </p>
                </div>
                <div className="image">
                  <div className="id-image"></div>
                </div>
              </div>
              <div className="yellow-arrow">&nbsp;</div>
              <div className="step">
                <div className="content">
                  <h3>
                    MINT YOUR SUPER
                    <br />
                    BADGE NFT
                  </h3>
                  <p>
                    Mint a non-transferable identity
                    <br />
                    badge NFT to reside in your wallet
                    <br />
                    as proof of access.
                  </p>
                </div>
                <div className="image">
                  <div className="badge-image"></div>
                </div>
              </div>
              <div className="glow"></div>
            </div>
          </div>
        </div>
        <SCLoader
          modal={true}
          isLoading={isLoading}
          loadingText={loadingText}
        />
      </div>
    </SuperChampsLayout>
  );
};

export default SuperBadge;
