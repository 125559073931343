import { verifyYoutubeAction } from '@/api/quests';
// import { localStorage } from '@/common/utils';

const VerifyYoutubeAction = async (
  userId: string,
  payload: any,
): Promise<boolean> => {
  try {
    const data = await verifyYoutubeAction(userId, payload);
    const { success } = data;
    if (success) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.error('Error in VerifyYoutubeAction \n', e);
    return false;
  }
};

export default VerifyYoutubeAction;
