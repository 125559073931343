import { connectTwitter } from '@/api/quests';
import LStorage from '@/common/storage';
import utils, { isBrowser, localStorage } from '@/common/utils';

import { TTask } from '../types';

const TwitterConnect = async (userId: string, quest: TTask) => {
  const loader = utils.el('quests-loader');
  loader!.classList.add('show');
  try {
    const twitterDetails = JSON.parse(
      localStorage.getItem('TWITTER_DETAILS') || '{}',
    );
    if (twitterDetails?.userAccessTokenData?.access_token) {
      if (isBrowser()) {
        let redirectUrl = quest.challengeRedirection;
        const xUserRegexp = new RegExp('{twitter_user_name}', 'g');
        const referralCode = twitterDetails.userProfileData.data.username;
        redirectUrl = redirectUrl.replace(xUserRegexp, referralCode);
        window.open(redirectUrl, '_blank');
        const tasks = LStorage.get('SOCIAL_ACTIVITY_TASKS_V2') || [];
        LStorage.set('SOCIAL_ACTIVITY_TASKS_V2', [...tasks, quest.id]);
      }
      return;
    } else {
      // no existing twitter details, so connect twitter
      const data = await connectTwitter(userId);
      // open the URL in browser
      // localStorage.setItem('QUEST_IN_PROGRESS', JSON.stringify(quest));
      if (isBrowser()) {
        window.open(data.authUrl, '_blank');
      }
    }
  } catch (e) {
    console.error('Error in TwitterConnect \n', e);
  } finally {
    loader!.classList.remove('show');
  }
};

export default TwitterConnect;
