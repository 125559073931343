import { validateAndUpdateReferralCode } from '@/api/quests';

const ValidateAndUpdateReferralCode = async (
  userId: string,
  payload: {
    nftId: string;
    referralCode: string;
  },
) => {
  try {
    const data = await validateAndUpdateReferralCode(userId, payload);
    // TODO: copy the code and disaply toaster to user?
    return data;
  } catch (e) {
    console.error('Error in ValidateAndUpdateReferralCode \n', e);
  }
};

export default ValidateAndUpdateReferralCode;
