import { connectYoutube } from '@/api/quests';
import utils, { isBrowser, localStorage } from '@/common/utils';

import { TTask } from '../types';

const YouTubeMetrics = async (userId: string, quest: TTask, setTask: any) => {
  const loader = utils.el('quests-loader');
  loader!.classList.add('show');
  try {
    const youtubeDetails = JSON.parse(
      localStorage.getItem('YOUTUBE_DETAILS') || '{}',
    );
    if (youtubeDetails?.youtubeAuthScope) {
      setTask(quest);
      return;
    } else {
      // no existing youtube details, so connect youtube
      const data = await connectYoutube();
      // open the URL in browser
      localStorage.setItem('QUEST_IN_PROGRESS', JSON.stringify(quest));
      if (isBrowser()) {
        window.open(data.authorizationUrl, '_blank');
      }
    }
  } catch (e) {
    console.error('Error in youtubeMetrics \n' + userId, e);
  } finally {
    loader!.classList.remove('show');
  }
};

export default YouTubeMetrics;
