// import { toast } from 'react-toastify';

import { verifyDiscordAction } from '@/api/quests';
import { localStorage } from '@/common/utils';

// import { EDiscordVerificationTaskType } from '../types';

const VerifyDiscordAction = async (
  userId: string,
  payload: any,
): Promise<boolean> => {
  try {
    const data = await verifyDiscordAction(userId, payload);
    // TODO: open the URL in browser
    const { success, refreshToken } = data;

    const discordDetails = JSON.parse(
      localStorage.getItem('DISCORD_DETAILS_V2') || '{}',
    );
    if (discordDetails) {
      discordDetails.refreshToken = refreshToken;
      localStorage.setItem(
        'DISCORD_DETAILS_V2',
        JSON.stringify(discordDetails),
      );
    }

    // const action = payload.actionType;
    if (success) {
      // show toaster and change the user progression
      // if (action === EDiscordVerificationTaskType.VERIFY_ROLE.toString()) {
      //   toast.success('Discord role verification was successful');
      // } else {
      //   toast.success('Discord quest verification was successful');
      // }
      return true;
    } else {
      // if (action === EDiscordVerificationTaskType.VERIFY_ROLE.toString()) {
      //   toast.error('Discord role verification failed');
      // } else {
      //   toast.error('Discord quest verification failed');
      // }
      return false;
    }
  } catch (e) {
    console.error('Error in VerifyDiscordAction \n', e);
    return false;
  }
};

export default VerifyDiscordAction;
