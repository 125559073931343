import { verifySoulBoundNft } from '@/api/quests';

const VerifySoulBoundNft = async (userId: string) => {
  try {
    const data = await verifySoulBoundNft(userId);
    // TODO: open the URL in browser
    return data;
  } catch (e) {
    console.error('Error in VerifySoulBoundNft \n', e);
  }
};

export default VerifySoulBoundNft;
