import LStorage from '@/common/storage';
import { isBrowser } from '@/common/utils';

import { TTask } from '../types';

const SocialActivity = async (userId: string, quest: TTask) => {
  try {
    if (!isBrowser) {
      return '';
    }
    window.open(quest.challengeRedirection, '_blank');
    const tasks = LStorage.get('SOCIAL_ACTIVITY_TASKS_V2') || [];
    LStorage.set('SOCIAL_ACTIVITY_TASKS_V2', [...tasks, quest.id]);
  } catch (e) {
    console.error('Error in Invite \n', e);
  }
};

export default SocialActivity;
