import LStorage from '@/common/storage';
import { isBrowser } from '@/common/utils';

import { TTask } from '../types';

const OpenRandomLinkWithPopulatingReferralCode = async (
  quest: TTask,
  referralCode: string,
) => {
  try {
    if (!isBrowser) {
      return '';
    }
    const urls = quest.challengesUiMetaData.ctaLinks || [];
    const count = urls.length;
    const randomIndex = Math.floor(Math.random() * count);
    let url = urls[randomIndex];
    const regexp = new RegExp('{referral_code}', 'g');
    url = url.replace(regexp, referralCode);
    window.open(url, '_blank');
    const tasks = LStorage.get('SOCIAL_ACTIVITY_TASKS_V2') || [];
    LStorage.set('SOCIAL_ACTIVITY_TASKS_V2', [...tasks, quest.id]);
  } catch (e) {
    console.error('Error in Invite \n', e);
  }
};

export default OpenRandomLinkWithPopulatingReferralCode;
