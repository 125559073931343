import { isBrowser } from '@/common/utils';

const Invite = async (userId: string) => {
  try {
    if (!isBrowser) {
      return '';
    }
    const url = `${window.location.origin}?r=<referral_id>`;
    const data = await navigator.clipboard.writeText(url);
    // TODO: open the URL in browser
    return data;
  } catch (e) {
    console.error('Error in Invite \n', e);
  }
};

export default Invite;
