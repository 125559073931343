import { navigate } from 'gatsby';
import React, { Dispatch, SetStateAction } from 'react';

import { verifyTwitterConnect } from '@/api/quests';
import CONSTANTS from '@/common/constants';
import { isBrowser, localStorage } from '@/common/utils';
import { INotification } from '@/components/common/notification-modal';

// import { TTask } from '../types';

const VerifyTwitterConnect = async (
  userId: string,
  setNotification: Dispatch<SetStateAction<INotification>>,
): Promise<boolean | undefined> => {
  try {
    if (!localStorage.getItem('TWITTER_DETAILS')) {
      const params = new URLSearchParams(
        isBrowser() ? window.location.search : '',
      );
      const code = params.get('code');
      if (!code) {
        return false;
      }
      const payload = {
        authCode: code,
        appId: CONSTANTS.JRX_APP_ID,
        codeVerifier: localStorage.getItem('TWITTER_CODE_VERIFIER') || '',
      };
      const data = await verifyTwitterConnect(userId, payload);
      if (data) {
        localStorage.setItem('TWITTER_DETAILS', JSON.stringify(data));

        // const quest: TTask = JSON.parse(
        //   localStorage.getItem('QUEST_IN_PROGRESS') || '',
        // );

        // // opening task link in a new tab and redirecting the user to /quests page
        // if (isBrowser() && quest) {
        //   localStorage.removeItem('QUEST_IN_PROGRESS');
        //   if (quest.challengeRedirection) {
        //     window.open(quest.challengeRedirection, '_blank');
        //   }
        // }
        navigate('/quests');
        // toast.success('Twitter connection was successful!');
        return true;
      }
    } else {
      navigate('/quests');
      // toast.success('Twitter already connected!');
      return true;
    }
  } catch (e: any) {
    navigate('/quests');
    if (e?.data?.errorCode === 'social_id_already_in_use') {
      // toast.error('Failed to connect, Twitter account already in use!');
      setNotification({
        show: true,
        message: (
          <h4 className="tac mb-4">
            Twitter is already linked to an existing account. Please connect
            with a different account.
          </h4>
        ),
        ctaText: 'OKAY',
      });
    } else {
      console.error('Error in VerifyTwitterConnect \n', e);
      // toast.error('Twitter connection failed!');
      setNotification({
        show: true,
        message: (
          <h4 className="tac mb-4">
            Twitter authorisation failed. Please try again.
          </h4>
        ),
        ctaText: 'OKAY',
      });
    }
    return false;
  }
};

export default VerifyTwitterConnect;
