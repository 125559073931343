import { verifyUserAction } from '@/api/quests';

const VerifyUserAction = async (userId: string, payload: any) => {
  try {
    const data = await verifyUserAction(userId, payload);
    return data;
  } catch (e) {
    console.error('Error in VerifyUserAction \n', e);
  }
};

export default VerifyUserAction;
