import { verifyTwitterAction } from '@/api/quests';
// import { isBrowser } from '@/common/utils';

const VerifyTwitterAction = async (
  userId: string,
  twitterUrl: string,
  payload: any,
) => {
  try {
    // is opening auth Url required for verifying quests progression?
    // if (isBrowser()) {
    //   window.open(twitterUrl, '_blank');
    // }
    const data = await verifyTwitterAction(userId, payload);
    return data;
  } catch (e) {
    console.error('Error in VerifyTwitterAction \n', e);
  }
};

export default VerifyTwitterAction;
